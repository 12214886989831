import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Modal, Typography } from '@material-ui/core'
import Favorite from '@material-ui/icons/Favorite';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import CommentIcon from '@material-ui/icons/Comment';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import ComeAva from '../assets/images/c1.jpg'
import ComeAva2 from '../assets/images/c2.jpg'
import ComeAva3 from '../assets/images/c3.jpg'
import ComeAvaPrev from '../assets/images/prev.jpg'
import ComeAva5 from '../assets/images/c5.jpg'
import ComeAva6 from '../assets/images/c6.jpg'
import ComeAvaNext from '../assets/images/next.jpg'
import Badge from '@material-ui/core/Badge';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorder from '@material-ui/icons/BookmarkBorder';
import '../screens/home.css'




const useStyles = makeStyles(theme => ({
    root: {
        padding: 15,
        display: "flex",
        flexDirection: "column",
        marginBottom: 15,
    },
    rootTab: {
        backgroundColor: "#191d2b",
        color: "#fff"
    },
    blogimg: {
        width: "100%",
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.1)",
            transition: " all .4s cubic-bezier(0.01, 0.04, 0.46, 0.6)",
        }
    },
    title: {
        textAlign: "left",
        "& a": {
            textDecoration: "none",
            color: "#fff",
            transition: ".4s",
            cursor: "pointer",
            "&:hover": {
                color: theme.palette.primary.main,
                transition: ".4s"
            }
        }
    },
    Modalimg: {
        width: "100%",
        height: "100%"
    },
    modal: {
        width: "60%",
        height: "70%",
        margin: "auto",
        marginTop: 80,
        border: "none",
        [theme.breakpoints.down("xs")]: {
            width: "90%",
            heighrt: "60%"
        }
    },
    blgimg: {
        position: "relative",
        overflow: "hidden",

    },
    parentblog: {
        backgroundColor: "#191d2b",
        padding: 15,

    }
}
))
export default function Blog({ image, title, desc, href }) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid item lg={6} sm={12} className={classes.root}>
                <div className={classes.parentblog}>
                    <div className={classes.blgimg}>
                        <a>
                            <img onClick={() => setOpen(true)} className={classes.blogimg} src={image} alt={title} />
                        </a>
                        <Typography className={classes.title} variant="h5">
                            <a target="blank" href={href}>{title}</a>
                        </Typography>
                        <Typography variant="body1">{desc}</Typography>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 10 }}>
                            <FormControlLabel
                                control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite color="error" />} name="checkedH" />}
                                label="Like"
                            />
                            <Badge style={{cursor:"pointer"}} badgeContent={7} color="error">
                                <CommentIcon />
                            </Badge>
                            <AvatarGroup max={5}>
                                <Avatar colorDefault="error" alt="Remy Sharp" src={ComeAva3} />
                                <Avatar alt="Travis Howard" src={ComeAva2} />
                                <Avatar alt="Cindy Baker" src={ComeAva5} />
                                <Avatar alt="Agnes Walker" src={ComeAvaPrev} />
                                <Avatar alt="Trevor Henderson" src={ComeAva5} />
                                <Avatar alt="Trevor Henderson" src={ComeAva6} />
                                <Avatar alt="Trevor Henderson" src={ComeAvaNext} />
                            </AvatarGroup>
                            <FormControlLabel
                                control={<Checkbox icon={<BookmarkBorder />} checkedIcon={<BookmarkIcon  color="primary" />} name="checkedH" />}
                                label="Save"
                            />
                            
                        </div>
                    </div>
                </div>
            </Grid>
            <Modal className={classes.modal} open={open} onClose={() => setOpen(false)} >
                <img className={classes.Modalimg} src={image} alt={title} />
            </Modal>
        </>
    )
}
