import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import Title from '../components/title'
import { getTranslate } from '../localization/index'
import Skill from '../components/skill'
import WorkIcon from '@material-ui/icons/Work'
import MyStepper from '../components/MyStepper'
import SchoolIcon from '@material-ui/icons/School'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import ProgressBar from '../components/ProgressBar'


const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
    },
    skillGrid: {
        paddingTop: 60,
        paddingBottom: 60,
        paddingRight: 30,
        paddingLeft: 30,
        [theme.breakpoints.down('md')]: {
            paddingRight: 5,
            paddingLeft: 5,
        }
    },
    skillSecondTitle: {
        marginLeft: 15
    }
}))
export default function ResumeScreen() {
    const classes = useStyles()
    const translate = getTranslate()

    return (
        <Grid justify="flex-start" alignItems="flex-start" container className={classes.root}>
            <ProgressBar />
            <div class="custom-shape-divider-top-1615024576">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>
            <Grid className={classes.skillGrid} container item xs={12}>
                <Title title={translate.myskills} />
                <Grid item container direction="row">
                    <Grid item xs={12} md={12} lg={6} >
                        <Skill value={90} title="Python" />
                        <Skill value={75} title="Machine Learning" />
                        <Skill value={80} title="Statistical analysis and computing" />
                        <Skill value={90} title="Processing large data sets" />
                        <Skill value={80} title="Deep Learning" />
                        <Skill value={80} title="Mathematics" />
                        <Skill value={85} title="Data Visualization" />
                        <Skill value={70} title="R" />
                        <Skill value={90} title="SQL vs NoSQL" />
                        <Skill value={95} title="RESTful API" />

                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <Skill value={90} title="Javascript" />
                        <Skill value={95} title="React js" />
                        <Skill value={95} title="React Native" />
                        <Skill value={90} title="Node js" />
                        <Skill value={90} title="MongoDB" />
                        <Skill value={95} title="Express.js" />
                        <Skill value={80} title="PHP" />
                        <Skill value={95} title="HTML5" />
                        <Skill value={95} title="CSS" />
                        <Skill value={95} title="Git/Github" />


                    </Grid>
                </Grid>
            </Grid>

            <Grid className={classes.skillGrid} container item xs={12}>
                <Title title={translate.resume} />
                <Grid item container direction="row" justfy="flex-start" alignItems="center">
                    <BusinessCenterIcon style={{ fontSize: 36 }} />
                    <Typography className={classes.skillSecondTitle} variant="h4">
                        {translate.workingexperience}
                    </Typography>
                </Grid>
                <Grid style={{ marginTop: 15 }} cotainer>
                    <MyStepper steps={translate.workingSteps} />
                </Grid>

                <Grid item container direction="row" justfy="flex-start" alignItems="center">
                    <SchoolIcon style={{ fontSize: 36 }} />
                    <Typography className={classes.skillSecondTitle} variant="h4">
                        {translate.educationalqualifications}
                    </Typography>
                </Grid>
                <Grid style={{ marginTop: 30 }} cotainer>
                    <MyStepper steps={translate.educationalSteps} />
                </Grid>
            </Grid>

        </Grid>
    )
}
