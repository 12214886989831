import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import theme from '../theme/theme'
import { ViewHeadline } from '@material-ui/icons'


const useStyles = makeStyles(them => ({
    root: {
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 20,
    },
    main: {
        backgroundColor: "#191d2b",
        zIndex: 1,
        border: "1px solid #2e344e",
        borderTop: "5px solid #2e344e",
        padding: theme.spacing(8),
        transition: ".6",
        cursor:"pointer",
        height:"70vh",
        borderRadius:"10px",
        '&:hover': {
            borderTopColor: theme.palette.primary.main,
            transition: "1s",
            boxShadow: "2px 2px 12px #3b5eae, 2px 2px 19px #639cff",
            fontColor:"#f0f",
            borderRadius:"10px"
        }

    },
    title: {
        marginTop: 15,
        position: "relative",
        paddingBottom: 15,
        "&::before": {
            content: "''",
            position: "absolute",
            left: 0,
            top: "auto",
            bottom: 0,
            height: "2px",
            width: "50px",
            backgroundColor: "#2e344e"
        }
    },
    description: {
        marginTop: 15
    }
}))

export default function Service({ title, desc, icon, image }) {
    const classes = useStyles()
    return (
        <Grid item lg={4} md={6} xs={12} className={classes.root}>
            <Grid container className={classes.main} container alignItems="flex-start">
                {image}
                {icon}
                <Typography className={classes.title} variant="h5">{title}</Typography>
                <Typography variant="body1" className={classes.description}>
                    {desc}
                </Typography>
            </Grid>
        </Grid>
    )
}
