import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, TextField, Button } from '@material-ui/core'
import Title from '../components/title'
import { getTranslate } from '../localization/index'
import './contact.css'
import Contact from '../components/contact'
import CallOutlinedIcon from '@material-ui/icons/CallOutlined'
import EmailIcon from '@material-ui/icons/Email'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ProgressBar from '../components/ProgressBar'
import "./home.css"


const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
    },
    contactGrid: {
        paddingTop: 60,
        paddingBottom: 60,
        paddingRight: 30,
        paddingLeft: 30,
        [theme.breakpoints.down("xs")]: {
            paddingRight: 5,
            paddingLeft: 5,
        }
    },
    iconStyle: {
        fontSize: 35,
        color: theme.palette.primary.main,
    },
    FormGrid: {
        padding: 10
    },
    detailsGrid: {
        padding: 10,

    },
    getintouch: {
        textAlign: "left"
    },
    sendButton: {
        letterSpacing: 0,
        fontSize: 15
    }
}))
export default function ContactScreen() {
    const classes = useStyles()
    const translate = getTranslate()
    const btn = document.querySelector(".btn")
    if (btn) {
        btn.addEventListener("mousemove", (e) => {
            const xPos = e.pageX - e.target.offsetLeft
            const yPos = e.pageY - e.target.offsetTop
            e.target.style.setProperty("--x", `${xPos}px`);
            e.target.style.setProperty("--y", `${yPos}px`);
        })
    }
    return (
        <Grid justify="flex-start" alignItems="flex-start" container className={classes.root}>
            <ProgressBar />
            <div class="custom-shape-divider-top-1615024576">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>
            <Grid className={classes.contactGrid} container item xs={12}>
                <Title title={translate.contactme} />
                <Grid item container direction="row">
                    <Grid item className={classes.FormGrid} xs={12} md={12} lg={6} >
                        <Typography className={classes.getintouch} variant="h4" >Get In Touch </Typography>
                        <TextField style={{ marginTop: 25 }}
                            fullWidth
                            required
                            label={translate.entername}
                            placeholder={translate.entername}
                            variant="outlined"
                        />
                        <TextField style={{ marginTop: 25 }}
                            fullWidth
                            required
                            label={translate.enteremail}
                            placeholder={translate.enteremail}
                            variant="outlined"
                        />
                        <TextField style={{ marginTop: 25 }}
                            fullWidth
                            required
                            label={translate.entersubject}
                            placeholder={translate.entersubject}
                            variant="outlined"
                        />
                        <TextField style={{ marginTop: 25 }}
                            fullWidth
                            required
                            label={translate.entermessage}
                            defaultValue={translate.entermessage}
                            variant="outlined"
                            multiline={true}
                            rows={6}
                        />
                        <Button className="btn"
                            variant="contained"
                            color="primary"
                            style={{
                                marginTop: 25, display: "flex",
                                letterSpacing: 0,
                                fontSize: "15px"
                            }}
                        >
                            {translate.sendmessage}
                        </Button>
                    </Grid>
                    <Grid className={classes.detailsGrid} item xs={12} md={12} lg={6}>
                        <Contact
                            title={translate.phone}
                            text1={translate.tel}
                            icon={<CallOutlinedIcon className={classes.iconStyle} />} />
                        <Contact
                            title={translate.email}
                            text1="mehditalebi9675@gmail.com"
                            icon={<EmailIcon className={classes.iconStyle} />} />
                        <Contact
                            title={translate.address}
                            text1={translate.street}
                            icon={<LocationOnIcon className={classes.iconStyle} />} />
                    </Grid>
                </Grid>
            </Grid>


        </Grid>
    )
}
