import personalWeb from '../assets/images/personal.png'
import personalWebsite from '../assets/images/personalwebsite.png'
import digiDesign from '../assets/images/digiplus.png'
import ficasDesign from '../assets/images/ficas.png'
import disneyDesign from '../assets/images/disney.png'
import pubgizDesign from '../assets/images/pabgiz.png'
import parsDesign from '../assets/images/parsshir.png'
import lightmenuDesign from '../assets/images/lightmenu.png'
import smashMag from '../assets/images/smashmag.png'
import rabogoldgalleryDesign from '../assets/images/rabogoldgallery.png'
import blogImage1 from '../assets/images/blog-image-1.jpg'
import blogImage2 from '../assets/images/blog-image-2.jpg'
import blogImage3 from '../assets/images/blog-image-3.jpg'
import blogImage4 from '../assets/images/blog-image-4.jpg'
import blogImage6 from '../assets/images/blog-image-6.jpg'
import blogImage7 from '../assets/images/AboutImg.jpg'
import blogImage8 from '../assets/images/blog-image-8.jpg'
import blogImage9 from '../assets/images/blog-image-9.jpg'
import BigData from '../assets/images/BigData.jpeg'
import ObjectDetection from '../assets/images/ObjectDetection.webp'
import ImageCaptioning from '../assets/images/captiongenerator.gif'
import ImageClassification from '../assets/images/ImageClassification.png'
import HandDistance from '../assets/images/HandDistance.jpg'
import ElectricityConsumptionForecast from '../assets/images/ElectricityConsumptionForecast.jpg'
import EDA from '../assets/images/EDA.jfif'
export default {
    name: "mehdi talebi",
    home: "HOME",
    about: "ABOUT",
    resume: "RESUME",
    portfolio: "PORTFOLIOS",
    blog: "BLOGS",
    contact: "CONTACT",
    hi: "Hi, I'm ",
    hi2: "",
    desc: "I'm interested in data science, machine learning, deep learning, and computer vision, and I am currently pursuing a master's degree in Data Science at Shahid Beheshti University. My academic journey has been such that at every stage, my motivation and interest in data science have grown stronger. I am highly motivated to work in this field. I am looking for new challenges for learning and growth and am eager for opportunities to expand my knowledge. My master's thesis focuses on self-driving cars and multi-object tracking.",
    aboutme: "ABOUT ME",
    services: "SERVICES",
    fullname: "Full Name",
    age: "Age",
    nationality: "Nationality",
    languages: "Language",
    address: "Address",
    phone: "Phone",
    freelancer: "Freelance",
    iranian: "Iran",
    persian: "Persian",
    english: "English",
    street: "Tehran,Iran",
    available: "Available",
    tel: "(+98) 935 973 2264",
    downloadcv: "download cv",
    webdesign: "Web Design",
    webdevelopment: "Web Development",
    mobileapp: "Mobile Apllication",
    servisedesc: "Website design with JavaScript, React Js, jQuery for front-end and use PHP, Mysql, Node.js, firebase, REST APIs for back-end and use React Native for build web applications ",
    myskills: "MY SKILLS",
    workingSteps: [
        { id: 5, date: "Oct 2023 - Present", title: "Research Assistant in an AI and Robotics Lab", subtitle: "Shahid Beheshti University", content: "• Conducting research in the field of machine learning, artificial intelligence, and autonomous vehicles under the supervision of a research advisor • Designing and implementing new machine learning algorithms for perception, control, and autonomous vehicles • Tracking advances in machine learning, artificial intelligence, and self-driving vehicles" },
        { id: 4, date: "Oct 2021 - Present", title: "Freelancing", subtitle: "Data Scientist | Developer", content: "Work with various machine learning and deep learning models, statistical techniques, and mathematical algorithms to process and analyze large datasets of images and videos" },
        { id: 0, date: "Dec 2020 - Sep 2021", title: "Front-end Web Developer", subtitle: "Digikala.com", content: "Using Html, Css, javascript, ReactJs, ReactNative" },
        { id: 1, date: "Mar 2020 - Dec 2020", title: "Webmaster", subtitle: "Ficas.com (Switzerland)", content: "(Webmaster & front-end developer (Based on JavaScript, SEO(using google analytics and google search console to improve), wordpress plugins" },
        { id: 2, date: "Sep 2017 - Sep 2019", title: "Backend Web Developer", subtitle: "Pars-shir.com", content: "Based on PHP, wordpress, wordpress plugins" },
        { id: 3, date: "Sep 2017 - Sep 2019", title: "Frontend Web Developer", subtitle: "Pars-shir.com", content: "Using Html, Css, Bootstrap5, Javascript, jQuery" },
    ],
    educationalSteps: [
        { id: 3, date: "Sep 2022 - Present", title: "Master of Science (M.Sc.)", subtitle: "Shahid Beheshti University (SBU) - Tehran", content: "Artificial Intelligence and Data Science" },
        { id: 1, date: "Sep 2016 - Jan 2021", title: "Bachelor of Science (B.Sc.)", subtitle: "Amirkabir University of Technology (AUT) - Tehran", content: "Bachelor's degree in engineering" },
        { id: 2, date: "Sep 2012 - Jun 2016", title: "Higher Schoold Graduation", subtitle: "Shahid Beheshti College", content: "High school education" },
        { id: -2, date: "", title: "", subtitle: "", content: "" },
    ],
    workingexperience: "Working Experience",
    machinelearning:"Machine learning projects",
    machineLearning:"Machine Learning",
    serviceml:"I'm open to taking on machine learning projects if requested, demonstrating my readiness to contribute effectively.",
    deepLearning:"Deep Learning",
    servicedl:"I'm capable of managing deep learning projects and I'm ready to undertake complex challenges within this domain whenever necessary.",
    computerVision:"Computer Vision",
    servicecv:"I'm passionate about computer vision projects, and I'm ready to engage in tasks involving deep learning and complex challenges within this field.",
    frontend:"Front end projects",
    educationalqualifications: "Educational Qualifications",
    contactme: "CONTACT ME",
    getintouch: "Get In Touch",
    entername: "Enter your name",
    enteremail: "Enter your email",
    entersubject: "Enter your subject",
    entermessage: "Enter your message",
    sendmessage: "SEND MESSAGE",
    email: "Email",
    portfoliosList: [
        { id: 4, href: "https://www.disneyplus.com/", title: "DisneyPlus", desc: "Rebuild DisneyPlus", image: disneyDesign },
        { id: 8, href: "", title: "Magazine", desc: "Online Magazine", image: smashMag },
        { id: 3, href: "https://digikala.com", title: "DigiKala", desc: "DigiPlus", image: digiDesign },
        { id: 1, href:"https://rabogoldgallery.com", title: "Online jewellery shop", desc: "Web Application", image: rabogoldgalleryDesign},
        { id: 2, href:"https://mehditalebi.com", title: "PersonalWebsite", desc: "Web Application", image: personalWeb },
        { id: 2, href: "https://ficas.com", title: "Ficas", desc: "Crypto Currency", image: ficasDesign },
        { id: 5, href: "", title: "Pubgiz", desc: "Online multiplayer battle game", image: pubgizDesign },
        { id: 6, href: "https://www.pars-shir.com/", title: "Parsshir", desc: "Manufacturer of gaz valves", image: parsDesign },
        { id: 7, href: "", title: "Lightmenu", desc: "Online food ordering", image: lightmenuDesign  },
        { id: 9, href:"", title: "PersonalWebsite ||", desc: "Web Application", image: personalWebsite },  
    ],
    portfoliosmlList: [
        { id: 7, href:"", title: "Big Data Project", desc: "Analysis and implementation of machine learning models to detect intrusion attacks.", image: BigData },
        { id: 2, href:"", title: "Object Detection", desc: "Object detection using YOLOv8 by implementing a streamlit web application", image: ObjectDetection },
        { id: 4, href:"", title: "Image Captioning", desc: "Using CNN & LSTM neural networks for training and evaluation on the Flickr8k dataset", image: ImageCaptioning },
        { id: 8, href:"", title: "Image Classification", desc: "Hidden Markov model for image classification on the Fashion_MNIST dataset", image: ImageClassification },
        { id: 3, href:"", title: "Distance detection", desc: "Hand Distance Measurement with Normal Webcam | OpenCV Python", image: HandDistance },
        { id: 1, href:"", title: "Electricity consumption forecast", desc: "Forecasting the amount of electricity consumption using the RNN model (LSTM)", image: ElectricityConsumptionForecast},
        { id: 2, href:"", title: "EDA", desc: "Exploratory data analysis of annual salaries from various data science fields", image: EDA },
    ],
    blogsList: [
        { id: 1, href: "", title: "BlockChain", desc: "Mehdi", image: blogImage4 },
        { id: 2, href: "", title: "MachineLearning", desc: "Mehdi", image: blogImage6 },
        { id: 3, href: "", title: "BigData", desc: "Mehdi", image: blogImage8 },
        { id: 4, href: "", title: "Hadoop", desc: "Mehdi", image: blogImage9 },
        { id: 5, href: "", title: "Mysql", desc: "Mehdi", image: blogImage3 },
        { id: 6, href: "", title: "SignalProccessing", desc: "Mehdi", image: blogImage7}, 
        { id: 7, href: "", title: "CloudComputing", desc: "Mehdi", image: blogImage2 }, 
        { id: 8, href: "", title: "Virtualization", desc: "Mehdi", image: blogImage1 }, 
    ],
    

}