import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({

    titled: {
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
            paddingTop: 23,
        },
        "&::after": {
            content: "''",
            position: "absolute",
            left: 0,
            top: "auto",
            bottom: 0,
            height: 5,
            borderRadius: 100,
            width: "50%",
            background: "#037fff",
            zIndex: 2,
            marginBottom:"50vh"
            

        },
        "&::before": {
            content: "''",
            position: "absolute",
            left: 0,
            top: "auto",
            bottom: 0,
            height: 5,
            borderRadius: 100,
            width: "100%",
            background: " rgba(3,127,255,.3)",
            zIndex: 3,
            marginBottom:"50vh"
        }
    },


}))
export default function Titled({ titled }) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Typography className={classes.titled} variant="h2">{titled}</Typography>
            <span className={classes.subtitle}>{titled}</span>
        </div>
    )
}
