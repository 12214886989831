import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Modal, Typography } from '@material-ui/core'



const useStyles = makeStyles(theme => ({
    root: {
        padding: 15,
        display: "flex",
        flexDirection: "column",
        marginBottom: 15,
    },
    portfolioimg: {
        width: "100%",
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.1)",
            transition: " all .4s cubic-bezier(0.01, 0.04, 0.46, 0.6)",
        }
    },
    title: {
        textAlign: "left",
        "& a": {
            textDecoration: "none",
            color: "#fff",
            transition: ".4s",
            cursor: "pointer",
            "&:hover": {
                color: theme.palette.primary.main,
                transition: ".4s"
            }
        }
    },
    Modalimg: {
        width: "100%",
        height: "100%"
    },
    modal: {
        width: "60%",
        height: "70%",
        margin: "auto",
        marginTop: 80,
        border:"none",
        [theme.breakpoints.down("xs")]: {
            width: "90%",
            heighrt: "60%"
        }
    },
    portimg: {
        position: "relative",
        overflow: "hidden",
        
    },
    parentPortfolio: {
        backgroundColor: "#191d2b",
        padding: 15,
        
    }
}
))
export default function Portfolio({ image, title, desc, href }) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    return (
        <>
            <Grid item lg={6} sm={12} className={classes.root}>
                <div className={classes.parentPortfolio}>
                    <div className={classes.portimg}>
                        <a>
                            <img onClick={() => setOpen(true)} className={classes.portfolioimg} src={image} alt={title} />
                        </a>
                        <Typography className={classes.title} variant="h5">
                            <a target="blank" href={href}>{title}</a>
                        </Typography>
                        <Typography variant="body1">{desc}</Typography>
                    </div>
                </div>
            </Grid>
            
            <Modal className={classes.modal} open={open} onClose={() => setOpen(false)} >
                <img className={classes.Modalimg} src={image} alt={title} />
            </Modal>
        </>
    )
}