import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import theme from '../theme/theme'



const useStyles = makeStyles(them => ({
    root: {
        width: "100 %",
        marginTop: 20
    },
    main: {
        backgroundColor: "#191d2b",
        zIndex: 1,
        border: "1px solid #2e344e",
        borderTop: "5px solid #2e344e",
        padding: theme.spacing(8),
        transition: ".6",
        borderRadius:10,
        padding:5,
        marginTop:50,
        '&:hover': {
            borderTopColor: theme.palette.primary.main,
            transition: "1s",
            boxShadow: "2px 2px 12px #3b5eae, 2px 2px 19px #639cff",
            fontColor:"#f0f",
            borderRadius:"10px"
        }

    },
    title: {
        textAlign: "left",
    },
    divIcon: {
        margin: 10,
        padding: 10,
        border: "1px solid #2e344e",
        marginRight: 25,
        paddingBottom: 5,
        marginTop:40,
        borderRadius:10
    },
    divDetails: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    description: {
        cursor: "pointer",
        transition: ".5s",
        "&:hover": {
            color: theme.palette.primary.main,
            transition: "1s"
        }

    }
}))

export default function Contact({ title, text1, text2, icon }) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Grid container className={classes.main} container direction="row" justify="flex-start" alignItems="center">
                <div className={classes.divIcon}>
                    {icon}
                </div>

                <div className={classes.divDetails}>
                    <Typography className={classes.title} variant="h6">{title}</Typography>
                    <Typography variant="body1" className={classes.description}>
                        {text1}
                    </Typography>
                    <Typography variant="body1" className={classes.description}>
                        {text2}
                    </Typography>
                </div>
            </Grid>
        </div>
    )
}
