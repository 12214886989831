import { createMuiTheme } from '@material-ui/core/styles'
import { getDirection, getFont } from '../localization/index'
import palette from './palette'
let theme = createMuiTheme()
theme = createMuiTheme({
    palette: palette,
    direction: getDirection(),
    spacing: 2.5,
    typography: {
        h1: {
            fontWeight: 700,
            fontSize: getDirection() === "rtl" ? 50 : 52,
            lineHeight: "4rem",
            fontFamily: getFont(),
            color: "#fff",
            [theme.breakpoints.down("md")]:{
                fontSize: getDirection() === "rtl" ? 40 : 42,
            }
        },
        h2: {
            fontWeight: 700,
            fontSize: getDirection() === "rtl" ? 38 : 40,
            lineHeight: "3.2857rem",
            fontFamily: getFont(),
            color: "#fff",
            [theme.breakpoints.down("md")]:{
                fontSize: getDirection() === "rtl" ? 32 : 34,
            }
        },
        h3: {
            fontWeight: 600,
            fontSize: getDirection() === "rtl" ? 30 : 32,
            lineHeight: "2.7rem",
            fontFamily: getFont(),
            textAlign: "left",
            color: "#fff"
        },
        h4: {
            fontWeight: 600,
            fontSize: getDirection() === "rtl" ? 26 : 28,
            lineHeight: "2.43rem",
            fontFamily: getFont(),
            color: "#fff",
            [theme.breakpoints.down("md")]:{
                fontSize: getDirection() === "rtl" ? 20 : 22,
            }
        },
        h5: {
            fontWeight: 600,
            fontSize: getDirection() === "rtl" ? 21 : 23,
            lineHeight: "2.14rem",
            fontFamily: getFont(),
            color: "#fff",
            [theme.breakpoints.down("md")]:{
                fontSize: getDirection() === "rtl" ? 16 : 18,
            }
        },
        h6: {
            fontWeight: 600,
            fontSize: getDirection() === "rtl" ? 16 : 18,
            lineHeight: "1.857rem",
            fontFamily: getFont(),
            color: "#fff",
            [theme.breakpoints.down("md")]:{
                fontSize: getDirection() === "rtl" ? 13 : 15,
            }
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: getDirection() === "rtl" ? 18 : 20,
            lineHeight: "2rem",
            fontFamily: getFont(),
            marginTop: 15,
            paddingLeft: 30,
            paddingRight: 30,
            [theme.breakpoints.down("md")]:{
                fontSize: getDirection() === "rtl" ? 16 : 18,
            }
        },
        body2: {
            fontWeight: 600,
            fontSize: getDirection() === "rtl" ? 15 : 17,
            fontFamily: getFont(),
            color: "#a4acc4",
            letterSpacing: 1,
            textAlign: "center",
            [theme.breakpoints.down("md")]:{
                fontSize: getDirection() === "rtl" ? 12 : 14,
            }
        },
        body1: {
            fontWeight: 400,
            fontSize: getDirection() === "rtl" ? 16 : 18,
            lineHeight: "1.8rem",
            fontFamily: getFont(),
            color: "#a4acc4",
            textAlign: getDirection() === "rtl" ? 'justify' : "left",
            [theme.breakpoints.down("md")]:{
                fontSize: getDirection() === "rtl" ? 13 : 15,
            }
        },
        button: {
            fontWeight: 400,
            fontSize: getDirection() === "rtl" ? 12 : 14,
            fontFamily: getFont(),
            color: "#fff",
            letterSpacing: 2,
            [theme.breakpoints.down("md")]:{
                fontSize: getDirection() === "rtl" ? 10 : 12,
            }
        }
    },
})
export default theme