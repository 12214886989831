import React from 'react'
import ContentLoader from 'react-content-loader'

const DoorDashFavorite = props => (
  <ContentLoader
    width={562}
    height={400}
    viewBox="0 0 450 400"
    backgroundColor="#aaa"
    foregroundColor="#ccc"
    {...props}
  >
    <rect x="43" y="364" rx="4" ry="4" width="271" height="6" />
    <rect x="44" y="345" rx="3" ry="3" width="100" height="6" />
    <rect x="42" y="77" rx="10" ry="10" width="388" height="250" />
  </ContentLoader>
)

DoorDashFavorite.metadata = {
  name: 'Nic Bovee', // My name
  github: 'ghettifish', // Github username
  description: 'A simple favorite from the DoorDash local favorites.', // Little tagline
  filename: 'DoorDashFavorite', // filename of your loader
}

export default DoorDashFavorite