import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'
import Title from '../components/title'
import { getTranslate } from '../localization/index'
import MainImage from '../assets/images/portfolio.jpg'
import MachineLearning from '../assets/images/machine-learning.gif'
import DeepLearning from '../assets/images/deepLearning.gif'
import ComputerVision from '../assets/images/computerVision.jpg'
import AboutImg from '../assets/images/AboutImg.jpg'
import Service from '../components/service'
import PaletteIcon from '@material-ui/icons/Palette'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import CodeIcon from '@material-ui/icons/Code'
import ProgressBar from '../components/ProgressBar'
import './home.css'


const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
    },
    aboutGrid: {
        paddingTop: 60,
        paddingBottom: 60,
        paddingRight: 30,
        paddingLeft: 30,
        // height: "100%",
    },
    MainImage: {
        width: "100%",
        height: "100%"
    },
    ImageGrid: {
        position: "relative",
        height: "100%",
        "&::after": {
            content: "''",
            position: "absolute",
            right: 0,
            bottom: 0,
            height: "65%",
            width: 15,
            background: " rgb(3 127 255)",
            zIndex: 2
        },
        "&::before": {
            content: "''",
            position: "absolute",
            left: 0,
            top: 0,
            height: "65%",
            width: 15,
            background: " rgb(3 127 255)",
            zIndex: 2
        }
    },
    nameText: {
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            display: "block"
        }
    },
    aboutTextGrid: {
        paddingRight: 20,
        paddingLeft: 20,
        [theme.breakpoints.down('md')]: {
            padding: 0,
            marginTop: 25,
            marginBottom: 15

        }
    },
    iconStyle: {
        fontSize: 46,
        color: theme.palette.primary.main,
        position: " relative",
        top: 11,
        right: 7
    },
    serviceImage: {
        width: "100%",

    },
    aboutStyle:{
        height: "100%",
    }

}))
export default function AboutScreen() {
    const classes = useStyles()
    const translate = getTranslate()
    const btn = document.querySelector(".btn")
    if (btn) {
        btn.addEventListener("mousemove", (e) => {
            const xPos = e.pageX - e.target.offsetLeft
            const yPos = e.pageY - e.target.offsetTop
            e.target.style.setProperty("--x", `${xPos}px`);
            e.target.style.setProperty("--y", `${yPos}px`);
        })
    }

    return (
        <Grid justify="flex-start" alignItems="flex-start" container className={classes.root}>
            <ProgressBar />
            <div class="custom-shape-divider-top-1615024576">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>
            <Grid className={classes.aboutGrid} container item xs={12}>
                <Title title={translate.aboutme} />
                <Grid className={classes.aboutStyle} item container direction="row">
                    <Grid item className={classes.ImageGrid} xs={12} md={12} lg={6} >
                        <img className={classes.MainImage} src={AboutImg} alt={translate.name} />
                    </Grid>
                    <Grid className={classes.aboutTextGrid} item xs={12} md={12} lg={6}>
                        <Typography variant="h3">{translate.hi}<span className={classes.nameText}>{translate.name}</span>{translate.hi2}</Typography>
                        <Typography style={{ marginTop: 10 }} variant="body1">
                            {translate.desc}
                        </Typography>
                        <Typography variant="body1" style={{ marginTop: 15 }}>
                            <b style={{ width: 120, display: "inline-block" }}>{translate.fullname}</b>
                            {' : '}
                            {translate.name}
                        </Typography>
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                            <b style={{ width: 120, display: "inline-block" }}>{translate.age}</b>
                            {' : '}
                            27
                        </Typography>
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                            <b style={{ width: 120, display: "inline-block" }}>{translate.nationality}</b>
                            {' : '}
                            {translate.iranian}
                        </Typography>
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                            <b style={{ width: 120, display: "inline-block" }}>{translate.languages}</b>
                            {' : '}
                            {translate.persian} | {translate.english}
                        </Typography>
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                            <b style={{ width: 120, display: "inline-block" }}>{translate.address}</b>
                            {' : '}
                            {translate.street}
                        </Typography>
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                            <b style={{ width: 120, display: "inline-block" }}>{translate.phone}</b>
                            {' : '}
                            {translate.tel}
                        </Typography>
                        <Typography variant="body1" style={{ marginTop: 5 }}>
                            <b style={{ width: 120, display: "inline-block" }}>{translate.freelancer}</b>
                            {' : '}
                            {translate.available}
                        </Typography>
                        <div style={{ display: "flex", marginTop: 20 }}>
                                <Button target="_blank" href="/Mehdi-Talebi.pdf" className="btn" variant="contained" color="primary" style={{ letterSpacing: 0, fontSize: 15 }}>
                                    {translate.downloadcv}
                                </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid className={classes.aboutGrid} container item xs={12}>
                <Title title={translate.services} />
                <Grid item container direction="row">
                    <Service
                        image={<img className={classes.serviceImage} src={MachineLearning} />}
                        title={translate.machineLearning} desc={translate.serviceml}
                        icon={<PaletteIcon className={classes.iconStyle} />} />
                    <Service
                        image={<img className={classes.serviceImage} src={DeepLearning} />}
                        icon={<CodeIcon className={classes.iconStyle} />}
                        title={translate.deepLearning} desc={translate.servicedl} />
                    <Service
                        image={<img className={classes.serviceImage} src={ComputerVision} />}
                        icon={<SmartphoneIcon className={classes.iconStyle} />}
                        title={translate.computerVision} desc={translate.servicecv} />
                </Grid>
            </Grid>
        </Grid>
    )
}
