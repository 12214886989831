export default {
    primary: {
        main: "#037fff",
        contrastText: "#FFF",
        backgroundColor:"#191d2b"
    },
    secondary: {
        main: "#a4acc4",
    },
    third:{
        main:"#f50057"
    },
    text: {
        primary: "#FFF",
        secondary: "a1acc4"
    }
}