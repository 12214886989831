import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import Title from '../components/title'
import Titled from '../components/titled'
import { getTranslate } from '../localization/index'
import Portfolio from '../components/portfolio'
import Portfoliomml from '../components/portfolio'
import MyLoader from '../components/myloader'
import './home.css'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import MyStepper from '../components/MyStepper'
import Divider from '@material-ui/core/Divider';
import ProgressBar from '../components/ProgressBar'

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
    },
    skillGrid: {
        paddingTop: 60,
        paddingBottom: 60,
        paddingRight: 30,
        paddingLeft: 30,
        [theme.breakpoints.down('md')]: {
            paddingRight: 5,
            paddingLeft: 5,
        }
    },
    dividerStyle: {
            border: "none",
            height: "5px",
            width: "100%",
            flexShrink: 0,
            backgroundColor: "#037FFF",
            borderRadius: "10px",
            marginTop: "25px",
            marginBottom: "45px",
        },
}))
export default function PortfolioScreen() {
    const classes = useStyles()
    const translate = getTranslate()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setData(translate.portfoliosmlList)
            setLoading(false)
        }, 1500)
    }, [])
    return (
        <div className={classes.root}>
            <div class="custom-shape-divider-top-1615024576">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>
            
            <Grid className={classes.skillGrid} container item>
                <ProgressBar />
                <Title title={translate.portfolio} />
                
                <Grid item container direction="row" justfy="flex-start" alignItems="center">
                    <BusinessCenterIcon style={{ fontSize: 36 }} />
                    <Typography style={{ margin: 10 }} className={classes.skillSecondTitle} variant="h4">
                        {translate.machinelearning}
                    </Typography>
                </Grid>
                <Grid item container xs={12} direction="row" justify="flex-start" alignItems="center">

                    {loading ? (
                        <>
                            <MyLoader />
                            <MyLoader />
                            <MyLoader />
                            <MyLoader />
                        </>
                    ) : (
                        <>
                            {translate.portfoliosmlList.map((portfolioml) =>
                                <Portfolio key={portfolioml.id}
                                    title={portfolioml.title}
                                    desc={portfolioml.desc}
                                    image={portfolioml.image}
                                    href={portfolioml.href} />
                            )}

                        </>
                    )}
                    
                </Grid>
                <Divider className={classes.dividerStyle} />                            
                <Grid item container direction="row" justfy="flex-start" alignItems="center">
                    
                    <BusinessCenterIcon style={{ fontSize: 36 }} />
                    <Typography style={{ margin: 10,marginBottom:10 }} className={classes.skillSecondTitle} variant="h4">
                        {translate.frontend}
                    </Typography>
                </Grid>
                <Grid item container xs={12} direction="row" justify="flex-start" alignItems="center">
                    {loading ? (
                        <>
                            <MyLoader />
                            <MyLoader />
                            <MyLoader />
                            <MyLoader />
                        </>
                    ) : (
                        <>
                            {translate.portfoliosList.map((portfolio) =>
                                <Portfolio key={portfolio.id}
                                    title={portfolio.title}
                                    desc={portfolio.desc}
                                    image={portfolio.image}
                                    href={portfolio.href} />
                            )}

                        </>
                    )}

                </Grid>
            </Grid>
        </div>
    )
}
