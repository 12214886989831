import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ProfileImage from '../assets/images/MT.jpg';
import { getTranslate } from '../localization/index';
import "../index.css";
import HomeScreen from './homeScreen';
import AboutScreen from './aboutScreen';
import ResumeScreen from './resumeScreen';
import PortfolioScreen from './portfolioScreen';
import BlogScreen from './blogScreen';
import ContactScreen from './contactScreen';
import { Switch, Route, Link, BrowserRouter as Router, useHistory } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import { changeLanguage, lang } from '../localization/index';
import './index.css';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CameraIcon from '@material-ui/icons/Camera';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', position: "relative" },
  drawer: { [theme.breakpoints.up('sm')]: { width: drawerWidth, flexShrink: 0, }, },
  menuButton: {
    margin: theme.spacing(2),
    width: 50,
    height: 50,
    border: "1px solid #2e344e",
    position: "fixed",
    top: 5,
    left: 20,
    zIndex: 2000, // تغییر zIndex برای اطمینان از نمایش صحیح دکمه منوی همبرگری
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('sm')]: { display: 'none', },
  },
  topDrawer: { width: "100%", padding: 5, borderBottom: "1px solid #2e344e", textAlign: "center" },
  drawerPaper: { width: drawerWidth, backgroundColor: "#000000", height: "100vh", borderRight: "1px solid #2e344e", overflow:"hidden" },
  buttomDrawer: { padding: 15, borderTop: "1px solid #2e344e", },
  centerDrawer: { display: "flex", flex: 1, justifyContent: "center", alignItems: "center" },
  ProfileImage: { width: 230, height: 230, borderRadius: "100%", maxWidth: "100%", border: "3px solid #037fff", verticalAlign: 'middle' },
  content: { flexGrow: 1, paddingRight: theme.spacing(3), paddingLeft: theme.spacing(3), position: "relative" },
  drawerIcon: { position: "absolute", left: 20, }
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [page, setPage] = useState(0);
  const translate = getTranslate();
  const handleDrawerToggle = () => { setMobileOpen(!mobileOpen); };
  

  const drawer = (
    <>
      <div className={classes.topDrawer}>
        <img src={ProfileImage} alt={translate.name} className={classes.ProfileImage} />
      </div>
      <div className={classes.centerDrawer}>
        <List style={{ width: "100%" }}>
          <ListItem
            className={page === 0 ? null : "listItem"}
            style={{ backgroundColor: page === 0 ? "#037fff" : "transparent", paddingLeft: 0, paddingRight: 0 }}
            onClick={() => { setPage(0); setMobileOpen(false); }}
            button
            component={Link}
            to="/"
          >
            <HomeIcon className={classes.drawerIcon} />
            <ListItemText
              disableTypography={true}
              children={<Typography className="listItemText" style={{ color: page === 0 ? "#fff" : "#a4acc4" }} variant="body2">{translate.home}</Typography>}
            />
            <div className="overlay" />
          </ListItem>
          <ListItem
            className={page === 1 ? null : "listItem"}
            style={{ backgroundColor: page === 1 ? "#037fff" : "transparent", paddingLeft: 0, paddingRight: 0 }}
            onClick={() => { setPage(1); setMobileOpen(false); }}
            button
            component={Link}
            to="/about"
          >
            <PersonIcon className={classes.drawerIcon} />
            <ListItemText
              disableTypography={true}
              children={<Typography className="listItemText" style={{ color: page === 1 ? "#fff" : "#a4acc4" }} variant="body2">{translate.about}</Typography>}
            />
            <div className="overlay" />
          </ListItem>
          <ListItem
            className={page === 2 ? null : "listItem"}
            style={{ backgroundColor: page === 2 ? "#037fff" : "transparent", paddingLeft: 0, paddingRight: 0 }}
            onClick={() => { setPage(2); setMobileOpen(false); }}
            button
            component={Link}
            to="/resume"
          >
            <AssignmentIcon className={classes.drawerIcon} />
            <ListItemText
              disableTypography={true}
              children={<Typography className="listItemText" style={{ color: page === 2 ? "#fff" : "#a4acc4" }} variant="body2">{translate.resume}</Typography>}
            />
            <div className="overlay" />
          </ListItem>
          <ListItem
            className={page === 3 ? null : "listItem"}
            style={{ backgroundColor: page === 3 ? "#037fff" : "transparent", paddingLeft: 0, paddingRight: 0 }}
            onClick={() => { setPage(3); setMobileOpen(false); }}
            button
            component={Link}
            to="/portfolio"
          >
            <CameraIcon className={classes.drawerIcon} />
            <ListItemText
              disableTypography={true}
              children={<Typography className="listItemText" style={{ color: page === 3 ? "#fff" : "#a4acc4" }} variant="body2">{translate.portfolio}</Typography>}
            />
            <div className="overlay" />
          </ListItem>
          <ListItem
            className={page === 4 ? null : "listItem"}
            style={{ backgroundColor: page === 4 ? "#037fff" : "transparent", paddingLeft: 0, paddingRight: 0 }}
            onClick={() => { setPage(4); setMobileOpen(false); }}
            button
            component={Link}
            to="/blog"
          >
            <ChromeReaderModeIcon className={classes.drawerIcon} />
            <ListItemText
              disableTypography={true}
              children={<Typography className="listItemText" style={{ color: page === 4 ? "#fff" : "#a4acc4" }} variant="body2">{translate.blog}</Typography>}
            />
            <div className="overlay" />
          </ListItem>
          <ListItem
            className={page === 5 ? null : "listItem"}
            style={{ backgroundColor: page === 5 ? "#037fff" : "transparent", paddingLeft: 0, paddingRight: 0 }}
            onClick={() => { setPage(5); setMobileOpen(false); }}
            button
            component={Link}
            to="/contact"
          >
            <ContactPhoneIcon className={classes.drawerIcon} />
            <ListItemText
              disableTypography={true}
              children={<Typography className="listItemText" style={{ color: page === 5 ? "#fff" : "#a4acc4" }} variant="body2">{translate.contact}</Typography>}
            />
            <div className="overlay" />
          </ListItem>
        </List>
      </div>
      <div className={classes.buttomDrawer}>
        <Button color={lang === "en" ? "primary" : "secondary"} onClick={() => changeLanguage("en")}>English</Button>
        {"/"}
        <Button color={lang === "fa" ? "primary" : "secondary"} onClick={() => changeLanguage("fa")} style={{ fontFamily: "IRANSans", letterSpacing: 0 }}>فارسی</Button>
      </div>
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Router>
      <ScrollToTop />
      <div className={classes.root}>
        <CssBaseline />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{ paper: classes.drawerPaper, }}
              ModalProps={{ keepMounted: true, }} // Better open performance on mobile.
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{ paper: classes.drawerPaper, }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <Switch>
            <Route exact path="/" component={HomeScreen} />
            <Route path="/about" component={AboutScreen} />
            <Route path="/resume" component={ResumeScreen} />
            <Route path="/portfolio" component={PortfolioScreen} />
            <Route path="/blog" component={BlogScreen} />
            <Route path="/contact" component={ContactScreen} />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
