import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);

  const handleScroll = () => {
    const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
    const currentProgress = (window.pageYOffset / totalHeight) * 100;
    setProgress(currentProgress);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const progressBarStyle = {
    width: `${progress}%`,
    height: '5px',
    backgroundColor: '#8f00ff',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999,
  };

  return <div style={progressBarStyle} />;
};


export default ProgressBar