import personalWeb from '../assets/images/personal.png'
import personalWebsite from '../assets/images/personalwebsite.png'
import digiDesign from '../assets/images/digiplus.png'
import ficasDesign from '../assets/images/ficas.png'
import disneyDesign from '../assets/images/disney.png'
import pubgizDesign from '../assets/images/pabgiz.png'
import parsDesign from '../assets/images/parsshir.png'
import lightmenuDesign from '../assets/images/lightmenu.png'
import smashMag from '../assets/images/smashmag.png'
import rabogoldgalleryDesign from '../assets/images/rabogoldgallery.png'
import blogImage1 from '../assets/images/blog-image-1.jpg'
import blogImage2 from '../assets/images/blog-image-2.jpg'
import blogImage3 from '../assets/images/blog-image-3.jpg'
import blogImage4 from '../assets/images/blog-image-4.jpg'
import blogImage6 from '../assets/images/blog-image-6.jpg'
import blogImage7 from '../assets/images/AboutImg.jpg'
import blogImage8 from '../assets/images/blog-image-8.jpg'
import blogImage9 from '../assets/images/blog-image-9.jpg'
import BigData from '../assets/images/BigData.jpeg'
import ObjectDetection from '../assets/images/ObjectDetection.webp'
import ImageCaptioning from '../assets/images/captiongenerator.gif'
import ImageClassification from '../assets/images/ImageClassification.png'
import HandDistance from '../assets/images/HandDistance.jpg'
import ElectricityConsumptionForecast from '../assets/images/ElectricityConsumptionForecast.jpg'
import EDA from '../assets/images/EDA.jfif'
export default {
    name: "مهدی طالبی",
    home: "صفحه اصلی",
    about: "درباره من",
    resume: "رزومه",
    portfolio: "نمونه کارها",
    blog: "مقالات",
    contact: "ارتباط با من",
    hi: " سلام من ",
    hi2: " هستم ",
    desc: "من به علم داده، یادگیری ماشین، یادگیری عمیق و بینایی کامپیوتر علاقه‌مندم و در حال حاضر در رشته مهندسی کامپیوتر (گرایش علم داده) در دانشگاه شهید بهشتی در مقطع ارشد تحصیل می‌کنم و مسیر تحصیلی من به شکلی بود که در هر مرحله از آن انگیزه و علاقه من به علم داده را بیشتر کرد. با انگیزه قوی‌ای به کار در این زمینه نگاه دارم. من به دنبال چالش‌های جدید برای یادگیری و رشد هستم و به دنبال فرصت‌هایی برای گسترش دانش خود می‌گردم. پایان‌نامه‌ ارشدم بر روی خودروهای خودران و ردیابی اشیا تمرکز دارد.",
    aboutme: "معرفی",
    services: 'سرویس ها',
    fullname: "نام",
    age: "سن",
    nationality: "ملیت",
    languages: "زبان ها",
    address: "آدرس",
    phone: "تلفن تماس",
    freelancer: "فریلنسر",
    iranian: "ایران",
    persian: "فارسی",
    english: "انگلیسی",
    street: "ایران، تهران",
    available: "در دسترس",
    tel: "09359732264",
    downloadcv: "دانلود رزومه",
    webdesign: "طراحی وب",
    webdevelopment: "توسعه وب",
    mobileapp: "اپلیکیشن موبایل",
    servisedesc: "طراحی و برنامه نویسی فرانت اند وب سایت با استفاده از جاوااسکریپت و فریمورک ریکت جی اس و برنامه نویسی بک اند با Mysql, PHP, Node.js, firebase, REST APIs و هچنین طراحی وب اپلیکیشن با استفاده از ریکت نیتیو ",
    myskills: "مهارت ها",
    workingSteps: [
        { id: 5, date: "آبان 1402 - اکنون", title: "دستیار تحقیقاتی در آزمایشگاه هوش مصنوعی و رباتیک", subtitle: "دانشگاه شهید بهشتی", content: "• انجام تحقیقات در زمینه یادگیری ماشین، هوش مصنوعی و وسایل نقلیه خودران تحت نظارت استاد مشاور پژوهشی  • طراحی و پیاده سازی الگوریتم های جدید یادگیری ماشین برای ادراک و کنترل وسایل نقلیه خودران • دنبال کردن آخرین پیشرفت‌ها در یادگیری ماشین، هوش مصنوعی، و وسایل نقلیه خودران  " },
        { id: 4, date: "شهریور 1401 - اکنون", title: "فریلنسر", subtitle: "Data Scientist | Developer", content: " کار با مدل‌های مختلف یادگیری ماشین و یادگیری عمیق، تکنیک‌های آماری و الگوریتم‌های ریاضی برای پردازش و تجزیه و تحلیل مجموعه‌های داده بزرگ از تصاویر و ویدیوها"  },
        { id: 0, date: "آذر 1400 - شهریور 1401", title: "توسعه فرانت اند", subtitle: "Digikala.com", content: "توسعه فرانت اند سایت با Javascript " },
        { id: 1, date: "اسفند 1399 - آذر 1400", title: "مدیر سایت", subtitle: "Ficas.com", content: " وب مستر و توسعه دهنده فرانت اند سایت فیکاس سوئیس بر پایه استفاده از زبان جاوا اسکریپت و وردپرس و همچنین تحربه کار با افزونه های کاربردی وردپرس و استفاده از گوگل آنالیتکس و سرچ کنسول  برای ارتقای سئوی سایت" },
        { id: 2, date: "شهریور 1396 - شهریور 1398", title: "توسعه بک اند", subtitle: "Pars-shir.com", content: "طراحی بک اند با PHP و استفاده از وردپرس" },
        { id: 3, date: "شهریور 1396 - شهریور 1398", title: "توسعه فرانت اند", subtitle: "Pars-shir.com", content: "طراحی فرانت اند سایت با Html, Css, Bootstrap5, JavaScript, jQuery" },
    ],
    educationalSteps: [
        { id: 3, date: "مهر 1401 - اکنون", title: "کارشناسی ارشد", subtitle: " دانشگاه شهید بهشتی - تهران", content: "هوش مصنوعی/ علم داده " },
        { id: 1, date: "مهر 1394 - بهمن 1399", title: "کارشناسی", subtitle: "دانشگاه صنعتی امیر کبیر (پلی تکنیک تهران)", content: "تحصیلات مهندسی در مقطع کارشناسی" },
        { id: 2, date: "مهر 1390 - خرداد 1394", title: "دبیرستان", subtitle: "نمونه شهید بهشتی", content: "تحصیلات دبیرستان " },
    ],
    workingexperience: "تجربه های کاری",
    machinelearning:"پروژه های ماشین لرنینگ",
    machineLearning:"ماشین لرنینگ",
    serviceml:"من آماده هستم تا در صورت درخواست، پروژه های یادگیری ماشین را انجام دهم و آمادگی خود را برای مشارکت موثر نشان دهم.",
    deepLearning:"دیپ لرنینگ",
    servicedl:"من قادر به مدیریت پروژه های یادگیری عمیق هستم و هر زمان که لازم باشد آماده انجام چالش های پیچیده در این حوزه هستم.",
    computerVision:"بینایی کامپیوتر",
    servicecv:"من مشتاق پروژه های بینایی کامپیوتر هستم و آماده شرکت در کارهایی هستم که شامل یادگیری عمیق و چالش های پیچیده در این زمینه است.",
    frontend:"پروژه های فرانت اند",   
    educationalqualifications: "مدارک تحصیلی",
    contactme: "تماس با من",
    getintouch: "در تماس باشید",
    entername: "نام را وارد کنید",
    enteremail: "ایمیل را وارد کنید",
    entersubject: "موضوع را وارد کنید",
    entermessage: "پیام خود را بنویسید",
    sendmessage: "ارسال پیام",
    email: "ایمیل",
    portfoliosList: [
        { id: 4, href: "https://www.disneyplus.com/", title: "دیزنی پلاس", desc: "طراحی مجدد سایت دیزنی پلاس", image: disneyDesign },
        { id: 8, href: "", title: "مجله خبری", desc: "مجله آنلاین خبری", image: smashMag },
        { id: 3, href: "https://digikala.com", title: "دیجی کالا", desc: "طراحی صفحه دیجی پلاس و توسه دهنده وب سایت", image: digiDesign },
        { id: 1, href:"https://rabogoldgallery.com", title: "فروشگاه آنلاین خرید و فروش طلا و جواهر رابوگلدگالری", desc: "وب اپلیکیشن ", image: rabogoldgalleryDesign},
        { id: 1, href:"https://mehditalebi.com", title: "وب سایت شخصی", desc: "وبسایت شخصی دو زبانه", image: personalWeb },
        { id: 2, href: "https://ficas.com", title: "فیکاس", desc: "ارزهای دیجیتال", image: ficasDesign },
        { id: 5, href: "", title: "پابجیز", desc: "بازی آنلاین چند نفره پابجی", image: pubgizDesign },
        { id: 6, href: "https://www.pars-shir.com/", title: "پارس شیر", desc: "تولید شیرآلات گازسوز", image: parsDesign },
        { id: 7, href: "", title: "لایت منو", desc: "سفارش آنلاین غذا", image: lightmenuDesign  },
        { id: 9, href:"", title: "وب سایت شخصی ||", desc: "وب سایت شخصی", image: personalWebsite },  
    ],
    portfoliosmlList: [
        { id: 7, href:"", title: "بیگ دیتا (مه داده)", desc: "تجزیه و تحلیل و پیاده سازی مدل های ماشین لرنینگی برای تشخیص حملات نفوذ در شبکه های IOT", image: BigData },
        { id: 2, href:"", title: "تشخیص اشیا", desc: "تشخیص اشیا با استفاده از الگوریتم YOLOv8و پیاده سازی در بستر وب اپلیکیشن  Streamlit", image: ObjectDetection },
        { id: 4, href:"", title: "تولید خودکار شرح تصاویر", desc: "تولید خودکار شرح تصاویر با استفاده از اگوریتم های CNN و LSTM روی دیتاست Flickr8k", image: ImageCaptioning },
        { id: 8, href:"", title: "طبقه بندی تصاویر", desc: "استفاده از مدل مخفی مارکوف برای طبقه بندی تصاویر دیتاست Fashion_MNIST", image: ImageClassification },
        { id: 3, href:"", title: "تشخیص فاصله", desc: "تشخیص و محاسبه فاصله دست تا دوربین وبکم با استفاده از OpenCV و  Mediapipe", image: HandDistance },
        { id: 1, href:"", title: "پیشبینی مصرف برق", desc: "پیشبینی مصرف برق با استفاده از شبکه های عصبی بازگشتی LSTM", image: ElectricityConsumptionForecast},
        { id: 2, href:"", title: "تحلیل اکتشافی داده", desc: "بررسی و تحلیل اکتشافی داده های حقوق فیلدهای مختلف شغل دیتاساینس در سراسر جهان", image: EDA },
    ],
    blogsList: [
        { id: 1, href: "", title: "بلاک چین", desc: "مهدی", image: blogImage4 },
        { id: 2, href: "", title: "ماشین لرنینگ", desc: "مهدی", image: blogImage6 },
        { id: 3, href: "", title: "کلان داده ها", desc: "مهدی", image: blogImage8 },
        { id: 4, href: "", title: "هدوپ", desc: "مهدی", image: blogImage9 },
        { id: 5, href: "", title: "مای اس کیو ال", desc: "مهدی", image: blogImage3 },
        { id: 6, href: "", title: "پردازش سیگنال", desc: "مهدی", image: blogImage7}, 
        { id: 7, href: "", title: "محاسبات ابری", desc: "مهدی", image: blogImage2 }, 
        { id: 8, href: "", title: "مجازی سازی", desc: "مهدی", image: blogImage1 }, 
    ],
    

}


















