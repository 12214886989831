import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden, Typography } from '@material-ui/core'
import "./home.css"
import Particles from 'react-particles-js';
import IconButton from '@material-ui/core/IconButton';
import TelegramIcon from '@material-ui/icons/Telegram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { getTranslate } from '../localization/index'

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        overflow: "hidden",

    },
    rootCard: {
        display: "flex",
        backgroundColor: "#037fff"
    },

    nameText: {
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            display: "block"
        }
    },
    divIcons: {
        marginTop: 25,
    },
    iconButtonStyle: {
        border: "2px solid #2e344e",
        height: 45,
        width: 45,
        marginRight: 10,
        transition: ".5s",
        "&:hover": {
            border: theme.palette.primary.main,
            transition: "1s",
            color: theme.palette.primary.main,
        }
    },
    particle: {
        width: "100%",
        minHeight: "100vh",
        position: "relative",
        opacity: .5,
    },
}))
export default function HomeScreen() {
    const translate = getTranslate()
    const classes = useStyles()
    return (

        <Grid container item xs={12} className={classes.root}>
            <div style={{ width: "100%", position: "absolute", direction: "ltr", zIndex: "999999999999999999999999" }}>
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>
            </div>
            <Particles className={classes.particle}
                params={{
                    particles: {
                        number: {
                            value: 50
                        },
                        size: {
                            value: 4
                        },
                    },
                    interactivity: {
                        events: {
                            onhover: {
                                enable: true,
                                mode: "repulse"
                            }
                        }
                    }
                }}

            />


            <Grid container item style={{
                justifyContent: "center", alignItems: "center", position: "absolute",
                right: 0,
                left: 0,
            }}>
                <Typography variant="h1">{translate.hi}<span className={classes.nameText}>{translate.name}</span>{translate.hi2}</Typography>
                <Typography variant="subtitle1">
                    {translate.desc}
                </Typography>
                <div className={classes.divIcons}>
                    <a href="https://t.me/Mehdita" target="blank">
                        <IconButton style={{ borderWidth: 2, borderStyle: "solid", borderColor: "primary" }} className={classes.iconButtonStyle} color="secondary">
                            <TelegramIcon />

                        </IconButton>
                    </a>
                    <a href="https://www.linkedin.com/in/mehditalebi-/" target="blank">
                        <IconButton style={{ borderWidth: 2, borderStyle: "solid", borderColor: "primary" }} className={classes.iconButtonStyle} color="secondary">
                            <LinkedInIcon />
                        </IconButton>
                    </a>
                    <a href="https://www.instagram.com/mehdi.mt__" target="blank">
                        <IconButton style={{ borderWidth: 2, borderStyle: "solid", borderColor: "primary" }} className={classes.iconButtonStyle} color="secondary">
                            <InstagramIcon />
                        </IconButton>
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=989359732264" target="blank">
                        <IconButton style={{ borderWidth: 2, borderStyle: "solid", borderColor: "primary" }} className={classes.iconButtonStyle} color="secondary">
                            <WhatsAppIcon />
                        </IconButton>
                    </a>
                    <a href="https://twitter.com/mehditalebi9675" target="blank">
                        <IconButton style={{ borderWidth: 2, borderStyle: "solid", borderColor: "primary" }} className={classes.iconButtonStyle} color="secondary">
                            <TwitterIcon />
                        </IconButton>
                    </a>
                </div>
            </Grid>

        </Grid >
    )
}
// https://demo.timecode.ir/99-12/14/239236/modern-technology-idm-advertising-239236-1.mp3
