import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Title from '../components/title'
import { getTranslate } from '../localization/index'
import Blog from '../components/blog'
import MyLoaderBlog from '../components/myloaderBlog'
import ProgressBar from '../components/ProgressBar'
import './home.css'

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
        padding: 30,
        paddingTop: 60,
        width: "100%",
        [theme.breakpoints.down("xs")]: {
            paddingRight: 10,
            padiingLeft: 10,
        }
    }
}))
export default function BlogScreen() {
    const classes = useStyles()
    const translate = getTranslate()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setData(translate.portfoliosList)
            setLoading(false)
        }, 1500)
    }, [])
    return (
        <div className={classes.root}>
            <div class="custom-shape-divider-top-1615024576">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>
            <Title title={translate.blog} />
            <ProgressBar />
            <Grid item container xs={12} direction="row" justify="flex-start" alignItems="center">
                {loading ? (
                    <>
                        <MyLoaderBlog />
                        <MyLoaderBlog />
                        <MyLoaderBlog />
                        <MyLoaderBlog />
                    </>
                ) : (
                        <>
                            {translate.blogsList.map((blog) =>
                                <Blog key={blog.id}
                                    title={blog.title}
                                    desc={blog.desc}
                                    image={blog.image}
                                    href={blog.href} />
                            )}
                        </>
                    )}

            </Grid>
        </div>
    )
}
